import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import {
  CarCostDetails,
  CarCostParams,
  CarType,
  getMonthlyCarCost,
} from '../../lib/carCalculatorUtil';
import { formatMoney } from '../../lib/utils';
import Card from '../Card';
import NewSliderInput from '../forms/NewSliderInput';
import PlusMinusNumericInput from '../forms/PlusMinusNumericInput';
import * as styles from './CarCalculator.module.scss';
import { navigate } from 'gatsby-plugin-intl';
import {
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import NumericSliderInput from '../forms/NumericSliderInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import loanApplicationSlice from '../../redux/loanApplication';
import Badge from '../Badge/Badge';

function CarCalculator(props) {
  const dispatch = useDispatch();

  const isExpanded = true;
  const [carPrice, setCarPrice] = useState(100000);
  const [ownershipTime, setOwnershiptime] = useState(5);
  const [amortizationPeriod, setAmortizationPeriod] = useState(5);
  const [resalePrice, setResalePrice] = useState(carPrice * 0.5);
  const [insurancePerMonth, setInsurancePerMonth] = useState(500);
  const [taxPerYear, setTaxPerYear] = useState(360);
  const [kmPerYear, setKmPerYear] = useState(15000);
  const [fuelPrice, setFuelPrice] = useState(20);
  const [electricityPrice, setElectricityPrice] = useState(3);

  const [carType, setCarType] = React.useState<CarType>('electric');
  const [interest, setInterest] = useState(9.97);
  const [electricityConsumption, setElectricityConsumption] = useState(2);
  const [fuelConsumption, setFuelConsumption] = useState(0.5);

  useEffect(() => {
    let depreciationRate = 0.1;
    if (carPrice < 200_000) {
      depreciationRate = 0.05;
    } else {
      depreciationRate = 0.1;
    }

    const resalePrice = carPrice * (1 - depreciationRate) ** ownershipTime;
    setResalePrice(resalePrice);
  }, [ownershipTime, carPrice]);

  useEffect(() => {
    dispatch(loanApplicationSlice.actions.updateAppliedAmount(carPrice));
  }, [carPrice]);

  const handleCarType = (event, newCarType: CarType) => {
    setCarType(newCarType);
    switch (newCarType) {
      case 'electric':
      case 'phev':
      case 'hybrid':
        setElectricityPrice(2);
        setTaxPerYear(360);
        break;

      case 'gas':
        setFuelPrice(20);
        setTaxPerYear(4000);
        break;
      case 'diesel':
        setFuelPrice(25);
        setTaxPerYear(6000);
        break;
    }
  };

  const StyledToggleButton = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#FFF',
      backgroundColor: '#8940F7',
    },
    height: '60px',
    width: '100%',
    textTransform: 'none',
    color: '#1E1D20',
    backgroundColor: '#FFFFFF',
  });

  function calculateCost(): CarCostDetails {
    const params: CarCostParams = {
      amount: carPrice,
      resalePrice: resalePrice,
      carType: carType,
      fuelPrice: fuelPrice,
      fuelConsumption: fuelConsumption,
      electricityPrice: electricityPrice,
      electricityConsumption: electricityConsumption,
      kmPerYear: kmPerYear,
      insurancePerMonth: insurancePerMonth,
      taxPerYear: taxPerYear,
      ownershipTime: ownershipTime,
      amortizationPeriodInMonths: amortizationPeriod * 12,
      interestRatePercentage: interest,
    };

    return getMonthlyCarCost(params);
  }

  const usesGas: boolean =
    carType === 'diesel' ||
    carType === 'gas' ||
    carType == 'hybrid' ||
    carType == 'phev';

  const usesElectricity: boolean = carType === 'electric' || carType === 'phev';

  return (
    <>
      <Card
        className={styles.formWrapper}
        childrenOverflow="visible"
        freeSize={isExpanded}
      >
        <form id="loanForm" style={props.style}>
          <div className={styles.formCard}>
            <div>
              <NewSliderInput
                min={10_000}
                max={600_000}
                step={5_000}
                formatValue={formatMoney}
                label="Bilens pris"
                ariaLabel="Bilens pris"
                onChange={value => setCarPrice(value)}
                value={carPrice}
                showMinMaxLabels={true}
              />
              <PlusMinusNumericInput
                min={1}
                max={carPrice < 200_000 ? 20 : 15}
                ariaLabel={'Lånetid'}
                readOnlyTextInput
                label={'Lånetid'}
                onChange={value => setAmortizationPeriod(value)}
                value={amortizationPeriod}
              />
              <NumericSliderInput
                min={0}
                max={20}
                step={0.1}
                formatValue={value =>
                  value.toLocaleString('sv-SE').replace('.', ',') + ' %'
                }
                label="Ränta"
                ariaLabel="Ränta"
                onChange={value => setInterest(value)}
                value={interest}
                showMinMaxLabels={true}
              />
            </div>
          </div>
          <div className={styles.carSection}>
            <ToggleButtonGroup
              value={carType}
              exclusive
              onChange={handleCarType}
            >
              <StyledToggleButton value="electric">El</StyledToggleButton>
              <StyledToggleButton value="diesel">Diesel</StyledToggleButton>
              <StyledToggleButton value="gas">Bensin</StyledToggleButton>
              <StyledToggleButton value="hybrid">Mildhybrid</StyledToggleButton>
              <StyledToggleButton value="phev">
                Laddbar hybrid
              </StyledToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={styles.formCard}>
            <div>
              <NumericSliderInput
                min={500}
                max={10000}
                step={500}
                label="Antal mil/år"
                ariaLabel="Antal mil/år"
                onChange={value => setKmPerYear(value * 10)}
                value={kmPerYear / 10}
                showMinMaxLabels={true}
              />
            </div>
            <div>
              {usesElectricity && (
                <TextField
                  id="electricity-price"
                  label="Elpris kr/kWh (t ex 3 kr/kWh)"
                  className={styles.textinput}
                  type="text"
                  InputProps={{
                    inputProps: { pattern: '\\d+' },
                    endAdornment: (
                      <InputAdornment position="end">kr/kWh</InputAdornment>
                    ),
                  }}
                  value={electricityPrice && formatMoney(electricityPrice)}
                  onChange={e => {
                    const value =
                      parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                    setElectricityPrice(value);
                  }}
                  variant="filled"
                />
              )}
              {usesElectricity && (
                <TextField
                  id="Elförbrukning"
                  label="Elförbrukning (t ex 2 kWh/mil)"
                  className={styles.textinput}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kWh/mil</InputAdornment>
                    ),
                  }}
                  value={electricityConsumption}
                  onChange={e => setElectricityConsumption(e.target.value)}
                  variant="filled"
                />
              )}
              {usesGas && (
                <TextField
                  id="fuel-price"
                  label="Drivmedelspris kr/liter"
                  className={styles.textinput}
                  type="text"
                  InputProps={{
                    inputProps: { pattern: '\\d+' },
                    endAdornment: (
                      <InputAdornment position="end">kr/liter</InputAdornment>
                    ),
                  }}
                  value={fuelPrice && formatMoney(fuelPrice)}
                  onChange={e => {
                    const value =
                      parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                    setFuelPrice(value);
                  }}
                  variant="filled"
                />
              )}
              {usesGas && (
                <TextField
                  id="Bränsleförbrukning"
                  label="Bränsleförbrukning (t ex 0,5 l/mil)"
                  className={styles.textinput}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">l/mil</InputAdornment>
                    ),
                  }}
                  value={fuelConsumption}
                  onChange={e => setFuelConsumption(e.target.value)}
                  variant="filled"
                />
              )}
            </div>
          </div>

          <div className={styles.carSection}>
            <PlusMinusNumericInput
              min={1}
              max={carPrice < 200_000 ? 20 : 15}
              ariaLabel={'Ägandetid'}
              readOnlyTextInput
              label={'Ägandetid'}
              onChange={value => setOwnershiptime(value)}
              value={ownershipTime}
            />
            <NewSliderInput
              min={0}
              max={carPrice}
              formatValue={formatMoney}
              step={1}
              label="Estimerat säljpris"
              ariaLabel="Estimerat säljpris"
              onChange={value => setResalePrice(value)}
              value={resalePrice}
              showMinMaxLabels={true}
            />
          </div>
          <div className={styles.formCard}>
            <TextField
              id="insurance-per-month"
              label="Försäkring per månad"
              className={styles.textinput}
              type="text"
              InputProps={{
                inputProps: { pattern: '\\d+' },
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              }}
              value={insurancePerMonth && formatMoney(insurancePerMonth)}
              onChange={e => {
                const value =
                  parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                setInsurancePerMonth(value);
              }}
              variant="filled"
            />
            <TextField
              id="tax-per-year"
              label="Skatt per år"
              className={styles.textinput}
              type="text"
              InputProps={{
                inputProps: { pattern: '\\d+' },
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              }}
              value={taxPerYear && formatMoney(taxPerYear)}
              onChange={e => {
                const value =
                  parseInt(e.target.value.replaceAll(/\D/g, '')) || 0;
                setTaxPerYear(value);
              }}
              variant="filled"
            />
          </div>
          <div className={styles.exampleCostSection}>
            <div>Ungefärlig kostnad:</div>
            <div>
              <div>{formatMoney(calculateCost().totalMonthlyCost)}</div>
              <div>kr/mån</div>
            </div>
          </div>

          <Accordion
            className={styles.accordion}
            elevation={0}
            sx={{ '&:before': { height: '0px' } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Se hur vi räknat</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.subCostSection}>
                <Typography>Lånekostnad</Typography>
                <Typography>
                  {formatMoney(calculateCost().loanRepayment)} kr/mån
                </Typography>
              </div>
              <div className={styles.subCostSection}>
                <Typography>Värdeminskning</Typography>
                <Typography>
                  {formatMoney(
                    calculateCost().loanAmountAfterDepreciationPerMonth,
                  )}{' '}
                  kr/mån
                </Typography>
              </div>
              <div className={styles.subCostSection}>
                <Typography>Drivmedelskostnad</Typography>
                <Typography>
                  {formatMoney(calculateCost().fuelCostPerMonth)} kr/mån
                </Typography>
              </div>
              <div className={styles.subCostSection}>
                <Typography>Försäkring</Typography>
                <Typography>
                  {formatMoney(calculateCost().insurancePerMonth)} kr/mån
                </Typography>
              </div>
              <div className={styles.subCostSection}>
                <Typography>Skatt</Typography>
                <Typography>
                  {formatMoney(calculateCost().taxPerMonth)} kr/mån
                </Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        </form>
        <PrimaryButton
          onClick={() => {
            navigate('/ranteindex');
          }}
          className={styles.submitButton}
        >
          Se vad du kan få för ränta!
        </PrimaryButton>
        <div className={styles.carCostBadge}>
          <Badge color="green">Ingen kreditupplysning!</Badge>
        </div>
      </Card>
    </>
  );
}

export default CarCalculator;
