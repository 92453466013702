// extracted by mini-css-extract-plugin
export var accordion = "CarCalculator-module--accordion--77202";
export var carCostBadge = "CarCalculator-module--carCostBadge--f36cc";
export var carSection = "CarCalculator-module--carSection--6f0a5";
export var exampleCostSection = "CarCalculator-module--exampleCostSection--34d58";
export var formCard = "CarCalculator-module--formCard--79be0";
export var formWrapper = "CarCalculator-module--formWrapper--8f74f";
export var selectinput = "CarCalculator-module--selectinput--4025a";
export var subCostSection = "CarCalculator-module--subCostSection--8b80b";
export var submitButton = "CarCalculator-module--submitButton--66d51";
export var textinput = "CarCalculator-module--textinput--4d9d2";