export type CarType = 'electric' | 'phev' | 'hybrid' | 'gas' | 'diesel';

export type CarCostParams = {
  amount: number;
  resalePrice: number;
  carType: CarType;
  fuelPrice: number;
  fuelConsumption: number;
  electricityPrice: number;
  electricityConsumption: number;
  kmPerYear: number;
  insurancePerMonth: number;
  taxPerYear: number;
  ownershipTime: number;
  amortizationPeriodInMonths: number;
  interestRatePercentage: number;
};

export type CarCostDetails = {
  loanRepayment: number;
  loanAmountAfterDepreciationPerMonth: number;
  insurancePerMonth: number;
  taxPerMonth: number;
  fuelCostPerMonth: number;
  totalMonthlyCost: number;
};

export function getMonthlyCarCost({
  amount,
  resalePrice,
  carType,
  fuelPrice,
  fuelConsumption,
  electricityPrice,
  electricityConsumption,
  kmPerYear,
  insurancePerMonth,
  taxPerYear,
  ownershipTime,
  amortizationPeriodInMonths,
  interestRatePercentage,
}: CarCostParams): CarCostDetails {
  const setupFee = 398;
  const loanAmountAfterDepreciation = amount - resalePrice;

  const interestRate = interestRatePercentage / 100;

  let fuelCostPerYear = 0;
  const swedishMiles = kmPerYear / 10;
  switch (carType) {
    case 'electric':
      fuelCostPerYear =
        electricityPrice * electricityConsumption * swedishMiles;
      break;
    case 'phev':
      fuelCostPerYear =
        (electricityPrice * electricityConsumption * 0.9 +
          fuelPrice * fuelConsumption * 0.1) *
        swedishMiles;
      break;
    case 'hybrid':
    case 'gas':
    case 'diesel':
      fuelCostPerYear = fuelPrice * fuelConsumption * swedishMiles;
      break;
  }

  const loanRepayment =
    interestRate == 0
      ? 0
      : (interestRate /
          12 /
          (1 - Math.pow(1 + interestRate / 12, -amortizationPeriodInMonths))) *
        (amount + setupFee);

  const deprieciationPerMonth = Math.round(
    loanAmountAfterDepreciation / (ownershipTime * 12),
  );

  const taxPerMonth = Math.round(taxPerYear / 12);
  const fuelPerMonth = Math.round(fuelCostPerYear / 12);
  const carCostDetails: CarCostDetails = {
    loanRepayment: Math.round(loanRepayment),
    loanAmountAfterDepreciationPerMonth: deprieciationPerMonth,
    insurancePerMonth: insurancePerMonth,
    taxPerMonth: taxPerMonth,
    fuelCostPerMonth: fuelPerMonth,
    totalMonthlyCost: Math.round(
      loanRepayment +
        deprieciationPerMonth +
        insurancePerMonth +
        taxPerMonth +
        fuelPerMonth,
    ),
  };

  return carCostDetails;
}
