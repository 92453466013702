import React from 'react';

import Layout from '@components/Layout/Layout';
import NyhetPink from '@icons/2.0/illustrations/NyhetPink.svg';
import Container from '@components/Container';
import TextHero from '@components/TextHero';
import * as styles from './car-calculator.module.scss';
import '@src/sentry'; // Enable Sentry monitoring for errors
import { useMediaQuery } from 'react-responsive';
import CarCalculator from '@src/components/CarCalculator/CarCalculator';
import Badge from '@src/components/Badge/Badge';

const CarCalculatorFormPage = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 896px)' });

  return (
    <Layout path="/car-calculator" theme="soft">
      <div className={styles.wrapper}>
        <Container className={styles.carCalculatorWrapper}>
          <div className={styles.carCalculatorContainer}>
            <TextHero
              className={styles.textHero}
              leftAligned
              size="large"
              title="Vad kostar en bil?"
              subtitle={`Vi hjälper dig ta reda på vad ditt nästa bilköp kostar!`}
              LeftIcon={NyhetPink}
            />
            {!isSmallScreen && (
              <div className={styles.carCostBadge}>
                <Badge color="red">Bilkostnad</Badge>
              </div>
            )}
            <ul>
              <li>Helt gratis</li>
              <li>Skippa Excel-arket</li>
              {!isSmallScreen && (
                <li>Få snabb överblick vad olika typer av bilar kostar</li>
              )}
            </ul>
          </div>
          <div className={styles.carCalculatorFormContainer}>
            <CarCalculator />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default CarCalculatorFormPage;
