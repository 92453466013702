import React, { CSSProperties, useEffect, useState } from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';

import * as styles from './NumericSliderInput.module.scss';
import * as inputStyles from '@components/TextInput/TextInput.module.scss';
import { Input, InputLabel } from '@mui/material';

type NewSliderInputProps = ReactSliderProps & {
  min?: number;
  max?: number;
  value: number;
  suffix?: string;
  label?: string;
  formatValue?: (_: number) => string;
  onChange?: (_: number) => void;
  id?: string;
  readOnlyTextInput?: boolean;
  showMinMaxLabels?: boolean;
  style?: CSSProperties;
  hideSlider?: boolean;
};

function NewSliderInput({
  min,
  max,
  value,
  label,
  formatValue,
  onChange,
  readOnlyTextInput,
  id,
  showMinMaxLabels,
  style,
  hideSlider,
  ...rest
}: NewSliderInputProps) {
  const [textFocused, setTextFocused] = useState(false);
  const [textInput, setTextInput] = useState('');

  const format = formatValue || (s => `${s}`);

  const onTextFocus = () => {
    if (readOnlyTextInput) return;
    setTextInput(`${value}`);
    setTextFocused(true);
  };

  const onTextBlur = () => {
    if (readOnlyTextInput) return;
    finishTextInput();
    setTextFocused(false);
  };

  const finishTextInput = () => {
    let res = parseInt(textInput);
    if (isNaN(res)) res = value;
    res = Math.max(min, Math.min(max, res));
    if (res !== value) {
      onChange?.(res);
    }
  };

  let textVal = `${value}`;
  if (formatValue) {
    textVal = formatValue(value);
  }
  if (textFocused) {
    textVal = textInput;
  }

  const onTextChange = e => {
    setTextInput(e.target.value);
  };

  const onTextKeyDown = e => {
    if (e.code === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1]?.focus();
      } else {
        e.target.blur();
      }
    }
  };

  useEffect(() => {
    if (value < min) {
      onChange?.(min);
    } else if (value > max) {
      onChange?.(max);
    }
  }, [min, max]);

  return (
    <div className={styles.sliderWrapper}>
      <div style={style}>
        <InputLabel className={styles.inputLabel}>{label}</InputLabel>
        <Input
          id={id}
          type={textFocused ? 'number' : 'text'}
          onFocus={onTextFocus}
          onBlur={onTextBlur}
          onKeyDown={onTextKeyDown}
          onChange={onTextChange}
          readOnly={readOnlyTextInput}
          label={label}
          value={textVal}
          className={inputStyles.input + ' ' + styles.input}
          inputProps={{
            min: min,
            max: max,
            className: styles.inputField,
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          min={min}
          max={max}
        />
      </div>
      {hideSlider ? null : (
        <div>
          <ReactSlider
            className={styles.slider}
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            {...rest}
          />
          {showMinMaxLabels && (
            <div className={styles.minMax}>
              <span>{format(min)}</span>
              <span>{format(max)}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewSliderInput;
